import React, { useState, useEffect } from 'react'

import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';

import { Helmet } from "react-helmet"

import history from './services/history'

import { NavigatorPage } from './modules'

import { setLanguage, languageFromLocale, t, currentLanguage } from './utils'

import config from './config.json'
import { ResetPassword } from './pages';

import { verifyPasswordResetCode, setNewPassword } from './services/auth'
import { fetchPartner } from './services/database';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3032C1',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FF6500'
    }
  }
})

const defaultSnackbar = {
  open: false,
  text: '',
  color: 'info'
}

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 99999,
    color: '#fff',
  }
}))

function App() {

  const [snackbar, setSnackbar] = useState(defaultSnackbar)
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage())

  const [mode, setMode] = useState('')
  const [actionCode, setActionCode] = useState('')

  const [email, setEmail] = useState('')

  const [loading, setLoading] = useState({
    setup: { isLoading: true }
  })

  const [appInfo, setAppInfo] = useState({
    name: config.name,
    description: config.description,
    favicon: null,
    logo: null,
    locales: config.locales
  })

  const setLocale = (locale) => {
    let lang = languageFromLocale(locale).code.toLowerCase()
    onLanguage(lang)
  }

  const acceptedLanguages = ['en', 'fr', 'nl', 'pl']

  const onLanguage = (lang) => {
    if(!acceptedLanguages.includes(lang)) {
      lang = 'en'
    }
    setLanguage(lang)
    setSelectedLanguage(lang)
  }

  const setup = async () => {
    const search = history.location.search
    const params = paramsFromSearch(search)
    if(!!params.lang) {
      onLanguage(params.lang)
    } else {}
    if(params.partner) {
      let p = await fetchPartner(params.partner)
      if(p) {
        let locales = config.locales
        if(params.locales) {
          locales = JSON.parse(decodeURIComponent(params.locales))
        }
        setAppInfo({
          name: `${p.name} Auth`,
          description: `${p.name} Auth`,
          favicon: p['favicon' + params.app_suffix] || p.favicon,
          logo: p['logo_large' + params.app_suffix] || p.logo_large,
          locales: locales
        })
      }
    }
    if(params.mode) {
      if(params.mode === 'resetPassword' && !!params.oobCode) {
        let verificationResult = await verifyPasswordResetCode(params.oobCode)
        if(verificationResult.error) {
          showSnackbar({ text: verificationResult.error.message, color: 'error' })
        } else {
          setMode(params.mode)
          setActionCode(params.oobCode)
          setEmail(verificationResult.email)
        }
      } else {
        showSnackbar({ text: t('error.invalid'), color: 'error' })
      }
    } else {
      showSnackbar({ text: t('error.unrecognized'), color: 'error' })
    }
    stopLoading('setup')
  }

  useEffect(() => {
    setup()
  }, [])

  const paramsFromSearch = (search) => {
    if(search.length <= 2) {
      return {}
    }
    search = search.substring(1, search.length)
    let components = search.split('&')
    let params = {}
    for(let i in components) {
      let keyValue = components[i].split('=')
      params[keyValue[0]] = keyValue[1]
    }
    return params
  }

  const onPasswordResetConfirm = async (password) => {
    startLoading('updatingPassword')
    let result = await setNewPassword(password, actionCode)
    stopLoading('updatingPassword')
    if(result.error) {
      showSnackbar({ text: result.error.message, color: 'error' })
    } else {
      showSnackbar({ text: t('auth.success'), color: 'success' })
    }
  }

  const showSnackbar = ({ text, color }) => {
    setSnackbar({ open: true, text, color})
  }

  const hideSnackbar = () => {
    setSnackbar(defaultSnackbar)
  }

  const startLoading = (key, message) => {
    let l = loading
    l[key] = { isLoading: true, message: message }
    
    setLoading({...l})
  }

  const stopLoading = (key) => {
    let l = loading
    l[key] = { isLoading: false }
    setLoading({...l})
  }

  const isLoading = () => {
    for(let key in loading) {
      if(Boolean(loading[key].isLoading)) {
        return key
      }
    }
    return null
  }

  const classes = useStyles()

  return (
    <div className="sec-wrap">
      <Helmet>
        <title>{ appInfo.name }</title>
        <meta name="description" content={ appInfo.description } />
        <link href={ appInfo.favicon } rel="shortcut icon" type="image/png" />
        <link href={ appInfo.favicon } rel="apple-touch-icon" />
      </Helmet>
      <ThemeProvider theme={theme}>
          <NavigatorPage 
            history={history}  
            logo={appInfo.logo}
            locales={appInfo.locales}
            setLocale={setLocale}            
            showSnackbar={showSnackbar}
            startLoading={startLoading}
            stopLoading={stopLoading}
            >
            { mode === 'resetPassword' ? (
            <ResetPassword 
              history={history}
              email={email}
              onConfirm={onPasswordResetConfirm}
              showSnackbar={showSnackbar}
              startLoading={startLoading}
              stopLoading={stopLoading} />  
            ) : null }
          </NavigatorPage>
        <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={hideSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert 
            onClose={hideSnackbar} 
            color={snackbar.color}>
            {snackbar.text}
          </Alert>
        </Snackbar>
        
        <Backdrop className={classes.backdrop} open={Boolean(isLoading())}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ marginBottom: 20 }}>{ !!loading[isLoading()] ? loading[isLoading()].message : '' }</span>
            <CircularProgress color="secondary" />            
          </div>
        </Backdrop>
      </ThemeProvider>
    </div>
  );
}

export default App;
