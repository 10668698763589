import fr from './fr.json'
import en from './en.json'
import pl from './pl.json'
import nl from './nl.json'
import cookie from 'react-cookies'

import { currentLanguage, LANGUAGE_C_KEY } from '.'

const source = {
  fr,
  en,
  pl,
  nl
}

const t = (key, params) => {
  const language = currentLanguage()
  const components = key.split('.')
  const section = source[language][components[0]]
  if(!section) {
    return `Missing translation for ${key}`
  }
  let value = section[components[1]]
  if(!value) {
    return `Missing translation for ${key}`
  }
  if(!!params) {
    for(let p in params) {
      value = value.replace(`%{${p}}`, params[p])
    }
  }
  return value
}

const setLanguage = (l) => {
  cookie.save(LANGUAGE_C_KEY, l)
}

export default t
export { setLanguage }