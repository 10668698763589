import firebase from './firebase'
import { t } from '../utils'

const auth = firebase.auth()

const verifyPasswordResetCode = async (code) => {
  try {
  const email = await auth.verifyPasswordResetCode(code)
  if(!email) {
    return { error: { message: t('error.unauthorized') }}
  }
  return { email: email }
  } catch(err) {
    console.log(err)
    return { error: { message: t(`error.${err.code}`) } }
  }
}

const setNewPassword = async (password, code) => {
  try {
    await auth.confirmPasswordReset(code, password)
    return {}
  } catch(err) {
    console.log(err)
    return { error: { message: t(`error.${err.code}`) } }
  }
}

export {
  verifyPasswordResetCode,
  setNewPassword
}