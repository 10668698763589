import React, { useState } from 'react'
import { t } from '../utils'

const ResetPassword = (props) => {
  
  const { email, onConfirm } = props

  const [password, setPassword] = useState('')

  return (
    <div className="sec">
      <div className="container">
        <div className="form-card">
          <div className="form__cont">
            <div className="pop__title-wrap">
              <h1 className="pop__h1">{ t('auth.reset_password') }</h1>
            </div>
            <div className="f__form-wrap">
              <div className="form w-form">
                <div>{`${t('auth.for')} `}<strong>{ email }</strong></div>
                <div id="password-form" name="password-form">
                  <div className="f__row">
                    <div className="f__cont-wrap">
                      <label htmlFor="email-3" className="f-form__label">{ t('auth.new_password') }</label>
                      <input type="password" className="f-form__text-field w-input" maxLength="256" onChange={e => setPassword(e.target.value)} value={password} />
                    </div>
                  </div>
                  <div className="btn-wrap">
                    <div className="f__btn-line-wrap">
                      <div onClick={() => onConfirm(password) } className="form__btn btn--full pop-up__btn w-button">{ t('auth.save') }</div>
                      <div className="red-line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword