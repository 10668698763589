import firebase from './firebase'

const PARTNERS_COL = 'public_partners'

const fetchCollection = async (collection) => {
  let snapshot = await collection.get()
  let documents = []
  snapshot.forEach(doc => {
    let d = doc.data()
    d.id = doc.id
    documents.push(d)
  })
  return documents
}

const fetchDocument = async (document) => {
  let snapshot = await document.get()
  return snapshot.data()
}

const fetchPartner = async (partnerId) => {
  const document = firebase.firestore().collection(PARTNERS_COL).doc(partnerId)
  return await fetchDocument(document)
}

export {
  fetchPartner
}