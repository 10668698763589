import React, { useEffect, useState } from 'react'
import { Footer } from '../components'

import { languageFromLocale, currentLanguage } from '../utils'

const NavigationPage = (props) => {

  const { locales, logo } = props

  const [languages, setLanguages] = useState([])

  useEffect(() => {
    let languageKeys = []
    let langs = []
    for(let i in locales) {
      let l = languageFromLocale(locales[i]).code
      if(languageKeys.indexOf(l) === -1) {
        languageKeys.push(l)
        langs.push({
          lang: l,
          locale: locales[i]
        })
      }
    }
    setLanguages([...langs])
  }, [props.locales])

  return (
    <div className="sec-wrap">
      <div data-collapse="medium" data-animation="default" data-duration="400" data-w-id="178ffc20-78ce-da7a-d810-f849a877aa0a" className="navbar w-nav">
        <div className="container">
          <div className="nav-wrap">
            <div className="brand-link w-nav-brand">
              { !!logo ? (
                <img src={logo} className="navbar-logo" alt="" />
              ) : null }
            </div>
            <div className="nav-link-wrap--lang">
              { languages.length > 1 ? languages.map((lang, li) => {
                return (
                  <div key={`locale_${li}`} onClick={() => { props.setLocale(lang.locale) }} className={`nav-link--lang w-nav-link ${lang.lang === currentLanguage() ? 'highlighted' : ''}`}>{ lang.lang }</div>
                )
              }) : null } 
            </div>            
          </div>
        </div>
        <div className="nav__g-line"></div>
      </div>
      {props.children}
      {/* <Footer /> */}
    </div>
  )
}

export default NavigationPage