import t, { setLanguage } from './strings'

import cookie from 'react-cookies'
import config from '../config.json'

const LANGUAGE_C_KEY = 'lawstudio_auth__selected_language'

const sortedArrayFromObject = (object, sortKey, asc) => {
  let array = []
  for(let key in object) {
    let item = object[key]
    item.id = key
    array.push(item)
  }
  array.sort((a,b) => {
    if(a[sortKey] > b[sortKey]) return asc ? 1 : -1
    if(a[sortKey] < b[sortKey]) return asc ? -1 : 1
    return 0
  })
  return array
}

const idFromName = (name) => {
  return name.replace(/\s/g, "")
}

const currentLanguage = () => {
  let language = cookie.load(LANGUAGE_C_KEY)
  if(!language) {
    language = languageFromLocale(config.default_locale).code
  }
  return language
}

const languageFromLocale = (locale) => {
  switch(locale) {
    case 'fr_FR':
      return { long: 'Français', code: 'fr' }
    case 'fr_BE':
      return { long: 'Français', code: 'fr' }
    case 'fr_CM':
      return { long: 'Français', code: 'fr' }
    case 'en_CM':
      return { long: 'English', code: 'en' }
    case 'en_GH':
      return { long: 'English', code: 'en' }
    case 'pl_PL':
      return { long: 'Polish', code: 'pl' }
    case 'nl_BE':
      return { long: 'Nederlands', code: 'nl' }
    default:
      return { long: '', code: '' }
  }
}

export {
  sortedArrayFromObject,
  idFromName,
  t,
  languageFromLocale,
  setLanguage,
  LANGUAGE_C_KEY,
  currentLanguage
}